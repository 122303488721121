:root { /* Define color scheme */
  --loyola-green:#005a3c;
  --hounds-grey:#d2d2d2;
  --black:#000000;
  --dark-green:#002d1e;
  --dark-grey:#4c4c4c;
  --bright-green:#0aa776;
}

.hide-for-small-only {display:none;}
.show-for-small-only {display:block;}

@media screen and (min-width: 40em) {
  .hide-for-small-only {display:block;}
  .show-for-small-only {display:none;}
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
